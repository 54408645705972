@import "./scss//helpers/Colors.scss";

.App {
  color: $main-white;
  font-family: "Maitree";
  background-color: $main-blue;

  button,
  input,
  textarea {
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  h1 {
    font-size: 3rem;
    font-family: "Playfair Display";
    font-weight: 600;
  }

  h2 {
    font-size: 1.8rem;
    line-height: 28px;
    font-weight: 400;
  }

  p,
  li {
    font-size: 1.4rem;
    line-height: 24px;
    font-weight: 400;
  }

  .btn-yellow-empty,
  .btn-yellow {
    background-color: $main-blue;
    border: solid 1px $main-yellow;
    border-radius: 1px;
    padding: 1.6rem;
    font-size: 1.6em;
    font-weight: 600;
    transition: all linear 0.25s;
  }

  .btn-yellow-empty {
    background-color: $main-blue;
    color: $main-yellow;
    svg {
      fill: $main-yellow;
    }
    &:hover {
      background-color: $main-yellow;
      color: $main-blue;
      transition: all linear 0.25s;

      svg {
        fill: $main-blue;
      }
    }
  }
  .btn-yellow {
    background-color: $main-yellow;
    color: $main-blue;
    &:hover {
      background-color: $main-blue;
      color: $main-yellow;
      transition: all linear 0.25s;
    }
  }
}
