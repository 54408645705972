@import "./helpers/Colors.scss";
@import "./helpers/HoverSupported.scss";
@import "./helpers/Media.scss";

.competence-container {
  width: 125px !important;
  height: 125px !important;
  border: solid 1px rgba($main-yellow, 0.35);
  margin-bottom: 3rem;

  img {
    width: 25px;
    height: 25px;
    margin-top: 1.6rem;

    &:first-of-type {
      margin-right: 0.8rem;
    }
  }

  &:focus {
    outline: 0;
  }

  .caption {
    text-align: right;
    color: $main-yellow;
    margin-bottom: 0.8rem;
    h2 {
      margin: 0;
      font-size: 1.4rem;
    }
  }
}
