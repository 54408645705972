@import "./helpers/Media.scss";
@import "./helpers/Colors.scss";

.progress-bar-projects{
  z-index: 10;
  display: inline-block;
  position: fixed;
  margin-top: 5.5rem;
  height: 2px;
  width:0%;
  background-color:$main-yellow;
}
.projets-container {
  padding-top: 5.5rem;
  padding-bottom: 3rem;

}
