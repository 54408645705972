@import "./helpers/Colors.scss";
@import "./helpers/Media.scss";

hr {
  display: none;
  @include md {
    display: block;

    height: 0.3rem !important;
    width: 80%;
    background-color: rgba($main-yellow, 0.25);
    &:last-child {
      display: none;
    }
  }
}

.projet {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @include md {
    margin: 10% 0 20% 0;
  }
  @include xl {
    margin: 15% 0 25% 0;
  }

  img {
    z-index: 1;
    width: 100%;
    height: auto;
    @include md {
      padding: 0;
      border-radius: 3px;
    }
  }

  .col-projet-caption {
    .caption {
      position: relative;
      z-index: 2;
      background-color: $main-white;
      @include md {
        margin-left: -10rem;
        margin-bottom: -5rem;
        padding: 3.2rem;
      }
      .caption-title,
      .caption-location {
        margin: 0;
        color: $main-blue;
      }
      .caption-title {
        font-size: 2rem;
        @include md {
          font-size: 3rem;
        }
      }

      .caption-location {
        font-size: 1.4rem;
        font-family: "Display fair";
        &:after {
          content: "";
          display: flex;
          height: 1px;
          background-color: $main-blue;
        }
      }
    }

    .btn-see-project {
      padding: 1.25rem;
      background-color: transparent;
      border: solid 1px $main-blue;
      color: $main-blue;
      font-size: 1.2rem;
      font-weight: 600;
      text-transform: uppercase;

      &:hover {
        color: $main-blue;
        text-decoration: none;
      }
    }
  }

  .col-projet-chevron {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $main-white;
    border-left: solid 1px $main-blue;

    .chevron {
      width: 25px;
      height: auto;
    }
  }
}
