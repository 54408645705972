@import "./helpers/Colors.scss";
@import "./helpers/Media.scss";
@import "./helpers/Forms.scss";

.intro-container {
  @media only screen and (max-height: 600px) and (orientation: landscape) {
    min-height: 115vh;
  }

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding-top: 5.5rem;

    @media only screen and (max-height: 600px) and (orientation: landscape) {
      top: 0;
      transform: translate(-50%,0);
    }

    // For IE
    .row, .col{
      flex: 1 0 100%;
    }


  h1 {
    font-size: 3.6rem !important;
    font-weight: 600;
    visibility: hidden;

    @include lg {
      font-size: 6rem !important;
    }
    @include xl {
      font-size: 8rem !important;
    }
  }

  .text-intro {
    visibility: hidden;
    p {
      padding: 0.8rem 0 2.4rem 0;
      font-family: "Playfair Display";
      font-weight: 400;
      font-size: 3.6rem !important;
      line-height: 4rem !important;
      color: rgba($main-white, 0.85);
      @include lg {
        font-size: 5rem !important;
        line-height: 6rem !important;
      }
      @include xl {
        font-size: 6rem !important;
        line-height: 7rem !important;
      }

      .word-yellow {
        color: $main-yellow;
        font-weight: 600;
      }
    }
  }

  hr {
    height: 1px;
    background-color: $main-white;
    visibility: hidden;
  }

  .buttons-row {
    padding: 1.6rem;
    @include xl {
      padding: 4.8rem;
    }
    button {
      visibility: hidden;
      &:last-child {
        margin-left: 2.4rem;
      }
    }

    svg {
      vertical-align: middle;
      height: 18px;
      width: 18px;
      margin-left: 0.5rem;
    }

    .link-unstyled {
      @include link-unstyled;
    }
  }
}

}
