@import "./helpers/Colors.scss";
@import "./helpers/Media.scss";
@import "./helpers/Forms.scss";
@import "./helpers/HoverSupported.scss";

.header-container {
  background-color: $main-blue;
  height: 5.5rem;

  .logo-oc {
    width: 120px;
    height: 30px;
    cursor: pointer;
    outline: 0;
  }

  .nav-item {
    margin: auto;
    .nav-link {
      margin-bottom: 0.8rem;
      color: $main-white;
      letter-spacing: 0.1rem;
      font-family: "Playfair Display";
      font-weight: 400;
      font-size: 4.8rem;
      line-height: 4.2rem;
      cursor: pointer;
      outline: 0;
      visibility: hidden;
      @include lg {
        padding: 0 0.8rem 0 0.8rem !important;
        font-size: 1.6rem;
        line-height: 1.6rem;
        visibility: visible;
      }
    }

    .selected {
      @include lg {
        &::after {
          content: "";
          display: block;
          position: relative;
          top: 0.5rem;
          height: 1px;
          width: 25px;
          margin: auto;
          background-color: $main-yellow;
        }
      }
    }
  }

  .navbar-collapse {
    @include max-lg {
      z-index: 99;
      position: fixed;
      top: 5.5rem;
      left: 0;
      height: 100%;
      width: 100vw;
      background-color: $main-blue;
      .navbar-nav {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -70%);
      }
    }
  }

  .collapse {
    visibility: hidden;
  }

  .collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
  }

  // BURGER MENU
  .btn-menu {
    z-index: 1;
    width: 25px;
    background-color: transparent;
    border: none;
    padding: 0;

    @include lg {
      display: none;
    }
  }

  span {
    display: block;
    border-radius: 2px;
    height: 2px;
    background: $main-yellow;
    transition: all 0.3s;
    position: relative;
  }

  .active span + span {
    margin-top: 18px;
  }

  .not-active span + span {
    margin-top: 8px;
  }

  .active span:nth-child(1) {
    animation: ease 0.7s top forwards;
  }

  .not-active span:nth-child(1) {
    animation: ease 0.7s top-2 forwards;
  }

  .active span:nth-child(2) {
    animation: ease 0.7s scaled forwards;
  }

  .not-active span:nth-child(2) {
    animation: ease 0.7s scaled-2 forwards;
  }

  .active span:nth-child(3) {
    animation: ease 0.7s bottom forwards;
  }

  .not-active span:nth-child(3) {
    animation: ease 0.7s bottom-2 forwards;
  }

  @keyframes top {
    0% {
      top: 0;
      transform: rotate(0);
    }

    50% {
      top: 20px;
      transform: rotate(0);
    }

    100% {
      top: 20px;
      transform: rotate(45deg);
    }
  }

  @keyframes top-2 {
    0% {
      top: 20px;
      transform: rotate(45deg);
    }

    50% {
      top: 20px;
      transform: rotate(0deg);
    }

    100% {
      top: 0;
      transform: rotate(0deg);
    }
  }

  @keyframes bottom {
    0% {
      bottom: 0;
      transform: rotate(0);
    }

    50% {
      bottom: 20px;
      transform: rotate(0);
    }

    100% {
      bottom: 20px;
      transform: rotate(135deg);
    }
  }

  @keyframes bottom-2 {
    0% {
      bottom: 20px;
      transform: rotate(135deg);
    }

    50% {
      bottom: 20px;
      transform: rotate(0);
    }

    100% {
      bottom: 0;
      transform: rotate(0);
    }
  }

  @keyframes scaled {
    50% {
      transform: scale(0);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes scaled-2 {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }
}
