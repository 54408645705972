@import "./helpers/Colors.scss";
@import "./helpers/Media.scss";
@import "./helpers/Forms.scss";
@import "./helpers/HoverSupported.scss";

.bio-container {
  padding-top: 5.5rem;

  .bio-left-part,
  .bio-right-part {
    display: flex;
    justify-content: center;
  }

  .bio-left-part {
    align-items: center;
    min-height: calc(100vh - 55px);
    background-color: $main-white;
    .about-text {
      background-color: $main-blue;
      z-index: 2;
      padding: 3rem;
      p {
        color: $main-yellow;
        font-weight: 600;
        visibility: hidden;
      }
      box-shadow: 0px -10px 10px -6px rgba(0, 0, 0, 0.25);

      @include md {
        padding: 5rem;
        border-radius: 2px;
      }
    }

    img {
      z-index: 1;
      display: block;
      margin: auto;
      width: 100%;
      max-width: 350px;
      height: auto;
    }

    .sentence {
      width: 280px;
      height: 68px;
      display: block;
      margin-left: auto;
      visibility: hidden;
    }
  }

  .bio-right-part {
    align-items: start;
    @include lg {
      align-items: center;
      min-height: calc(100vh - 55px);
    }
    .container-title-competences {
      margin-top: 5rem;
      padding: 0 3rem !important;
      text-transform: uppercase;
      h1 {
        visibility: hidden;
      }
    }
  }
}
