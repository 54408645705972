@import "./helpers/Media.scss";
@import "./helpers/Colors.scss";

.container-social-network {
  z-index: 99;
  margin: auto;
  visibility: hidden;

  @include lg {
    margin: auto;
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 1rem;
    top: 50vh;
    &:before {
      content: "";
      width: 0.6rem;
      height: 0.1rem;
      position: absolute;
      right: -1rem;
      top: 50%;
      background-color: $main-yellow;
    }
  }

  button {
    padding: 0;
    background-color: transparent;
    border: none;
    &:hover {
      scale: 1.25;
      transition: scale 0.25s ease-in-out;
    }
  }

  .logo-linked-in,
  .logo-github,
  .logo-instagram {
    width: 30px;
    margin: 2.2rem;

    @include lg {
      margin: 0.8rem 0.3rem;
      cursor: pointer;
    }
  }
}
